import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

class ResizableTextarea extends PureComponent {
  input = null;

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      rows: 5,
      minRows: 5,
      maxRows: 10,
    };
  }

  componentDidMount() {
    const textareaLineHeight = 24;
    const { maxRows } = this.state;
    const currentRows = ~~(this.input.scrollHeight / textareaLineHeight);
    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  }

  handleChange = (event) => {
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;
    const { onChange } = this.props;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.setState({
      value: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
    onChange(event.target.value);
  };

  render() {
    return (
      <Textarea
        ref={e => this.input = e}
        rows={this.state.rows}
        value={this.state.value}
        placeholder={'Enter your text here...'}
        onChange={this.handleChange}
      />
    );
  }
}

const Textarea = styled.textarea`
  width: 100%;
  border: none;
  background: ${p => p.theme.colors.gray.lighter};
  border-radius: 8px;
  padding: 12px;
  resize: none;
`;

ResizableTextarea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ResizableTextarea;
