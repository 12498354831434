import gql from 'graphql-tag';

export const crmUserData = gql`
  fragment CrmUserData on User {
    _id
    personalData {
      images
      firstName
      email
      timezone
    }
    serviceData {
      locale
      appsFlyerID
      campaign
      mediaSource
      payments {
        plan
        webPlan
        savedPlans
        savedPlansData
      }
    }
    createdAt
    lastActiveAt
    removed
  }
`;