import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SvgIcon, Button, Collapse } from 'core/ui/atoms';
import MenuItem, { menuItemStyle } from './MenuItem';
import { logout } from 'core/api/actions';

@withRouter
class Menu extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  };

  state = {
    opened: false,
  };

  items = [
    { to: '/users', text: 'Пользователи', Icon: SvgIcon.User },
    { to: '/operations', text: 'Операции', Icon: SvgIcon.Settings },
    { to: '/interpretations', text: 'Интерпретации', Icon: SvgIcon.Settings },
    { to: '/places', text: 'Места', Icon: SvgIcon.Settings },
    { to: '/flags', text: 'Флаги', Icon: SvgIcon.Settings },
    { to: '/promocodes', text: 'Промокоды', Icon: SvgIcon.Settings },
    { to: '/statistic', text: 'Статистика', Icon: SvgIcon.Settings },
    { to: '/affiliate', text: 'Партнерка', Icon: SvgIcon.Settings },
    { to: '/moderation/approve', text: 'Модерация', Icon: SvgIcon.Settings },
    { to: '/quiz/survey', text: 'Опросы', Icon: SvgIcon.Settings },
    { to: '/orders', text: 'Подписки', Icon: SvgIcon.Settings },
    { to: '/bot', text: 'Боты', Icon: SvgIcon.User },
  ];

  open = (opened) => {
    this.setState({ opened });
  };

  isActive(name) {
    const {
      location: { pathname },
    } = this.props;
    return pathname.includes(name);
  }

  render() {
    return (
      <MenuCont>
        {this.items.map(({ to, text, Icon, name, items }, key) => {
          if (items) {
            return (
              <MenuCollapsible key={key}>
                <MenuButton
                  active={this.isActive(name)}
                  onClick={() => this.open(name)}
                >
                  <Icon />
                  <span>{text}</span>
                </MenuButton>
                <Collapse
                  collapsed={this.state.opened !== name && !this.isActive(name)}
                >
                  {items.map((item, key2) => (
                    <MenuItem mini key={key2} {...item} />
                  ))}
                </Collapse>
              </MenuCollapsible>
            );
          }
          return <MenuItem key={key} to={to} text={text} Icon={Icon} />;
        })}
        <MenuButton logout onClick={logout}>
          <SvgIcon.Logout />
          Выйти
        </MenuButton>
      </MenuCont>
    );
  }
}

export default Menu;

const MenuCont = styled.div`
  margin-top: 80px;
  height: calc(100% - 120px);
  position: relative;
`;

const MenuCollapsible = styled.div``;

const MenuButton = styled((p) => <Button unstyled {...p} />)`
  ${menuItemStyle};
  ${(p) =>
    p.logout &&
    css`
      position: absolute;
      bottom: 99px;
    `}
  ${(p) =>
    p.active &&
    css`
      span {
        border-color: white;
      }
    `}
`;
