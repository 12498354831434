import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addDays } from 'date-fns';
import { useLazyQuery } from 'react-apollo';

import ControlPanel from 'crm/ui/components/ControlPanel';
import CRMAnalyticsData from 'crm/ui/components/CRMAnalyticsData';
import CRMAnalyticsUsers from 'crm/ui/components/CRMAnalyticsUsers';
import { logout } from 'core/api/actions';
import { withAccount } from 'core/api/accounts/accountContext';

import {
  GET_CRM_ANALYTICS_DATA,
  GET_USERS_FOR_CRM_ANALYTICS,
} from '../../api/apollo/queries';
import { Button } from '@mui/material';

const CrmPage = ({ currentUser }) => {
  const [campaign, setCampaign] = useState('');
  const [mediaSource, setMediaSource] = useState('');
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: 'selection',
  });
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(20);
  const [onlyPurchased, setOnlyPurchased] = useState(false);

  const { username, roles, accessKey } = currentUser || {};
  const isAdmin = (roles || []).includes('Admin');

  const [getCRMAnalyticsData, { called, loading, data, error }] =
    useLazyQuery(GET_CRM_ANALYTICS_DATA);
  const [
    getUsersForCRMAnalytics,
    {
      called: usersCalled,
      loading: usersLoading,
      data: usersData,
      error: usersError,
    },
  ] = useLazyQuery(GET_USERS_FOR_CRM_ANALYTICS);

  const getData = () => {
    setPage(0);
    getCRMAnalyticsData({
      variables: {
        input: {
          mediaSource,
          campaign,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onlyPurchased,
        },
      },
      fetchPolicy: 'network-only',
    });
    getUsersForCRMAnalytics({
      variables: {
        input: {
          mediaSource,
          campaign,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onlyPurchased,
        },
        page,
        count,
      },
      fetchPolicy: 'network-only',
    });
  };

  const loadPage = (newPage) => {
    setPage(newPage);
    getUsersForCRMAnalytics({
      variables: {
        input: {
          mediaSource,
          campaign,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onlyPurchased,
        },
        page: newPage,
        count,
      },
      fetchPolicy: 'network-only',
    });
  };

  const setOnlyPurchasedWithRefetch = (e) => {
    // console.log('newValue', e.target.value, e.target.value === 'on');
    const newValue = !onlyPurchased;
    setOnlyPurchased((st) => !st);
    setPage(0);
    getUsersForCRMAnalytics({
      variables: {
        input: {
          mediaSource,
          campaign,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onlyPurchased: newValue,
        },
        page: 0,
        count,
      },
      fetchPolicy: 'network-only',
    });
  };

  const onCsvDownload = () => {
    const url = `${
      process.env.REACT_APP_GRAPHQL_URI
    }/csv/users?c=${campaign}&ms=${mediaSource}&sd=${dateRange.startDate.toISOString()}&ed=${dateRange.endDate.toISOString()}&op=${onlyPurchased ? 'true' : 'false'}&accessKey=${accessKey}`;
    window.open(url, '_blank');
  };

  return (
    <AppContainer>
      <PageTitle>CRM Dashboard, {username}</PageTitle>
      <SignOutButton>
        <Button onClick={logout} variant='outlined'>
          Выйти
        </Button>
      </SignOutButton>
      <ControlPanel
        campaign={campaign}
        mediaSource={mediaSource}
        dateRange={dateRange}
        setCampaign={setCampaign}
        setMediaSource={setMediaSource}
        setDateRange={setDateRange}
        handleRequest={getData}
        buttonDisabled={loading || !campaign || !mediaSource}
        isAdmin={isAdmin}
      />
      {called && data ? (
        <CRMAnalyticsData
          data={data.getCRMAnalyticsData}
          loading={loading}
          error={error}
          called={called}
        />
      ) : null}
      {usersCalled && usersData ? (
        <CRMAnalyticsUsers
          data={usersData.getUsersForCRMAnalytics}
          loading={usersLoading}
          error={usersError}
          called={usersCalled}
          page={page}
          count={count}
          setPage={loadPage}
          onlyPurchased={onlyPurchased}
          setOnlyPurchased={setOnlyPurchasedWithRefetch}
          generateCsv={onCsvDownload}
        />
      ) : null}
    </AppContainer>
  );
};

CrmPage.propTypes = {
  currentUser: PropTypes.object,
};

const AppContainer = styled.div`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
`;

const SignOutButton = styled.span`
  position: absolute;
  top: 12px;
  right: 24px;
  ${(p) => p.theme.max('sm')`
    right: 12px;
  `}
`;

const PageTitle = styled.h1`
  text-align: center;
  ${(p) => p.theme.max('sm')`
      text-align: center;
      font-size: 32px;
      line-height: 38px;
      margin-top: 60px;
  `}
`;

export default withAccount(CrmPage);
