import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import * as rdrLocales from 'react-date-range/dist/locale';
import { useQuery } from 'react-apollo';
import { GET_AVAILABLE_ATTRIBUTION } from '../../api/apollo/queries';
import { Button } from '@mui/material';

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
  ${(p) => p.theme.max('sm')`
    padding-left: 12px;
    padding-right: 12px;
  `}
`;

const ControlPanel = ({
  campaign,
  mediaSource,
  dateRange,
  setCampaign,
  setMediaSource,
  setDateRange,
  handleRequest,
  buttonDisabled,
  isAdmin,
}) => {
  const { data, loading, error } = useQuery(GET_AVAILABLE_ATTRIBUTION);
  const campaigns =
    (data &&
      data.getAvailableAttribution &&
      data.getAvailableAttribution.campaigns) ||
    [];
  const mediaSources =
    (data &&
      data.getAvailableAttribution &&
      data.getAvailableAttribution.mediaSources) ||
    [];
  const usersCampaigns =
    (data &&
      data.getAvailableAttribution &&
      data.getAvailableAttribution.usersCampaigns) ||
    [];
  const usersMediaSources =
    (data &&
      data.getAvailableAttribution &&
      data.getAvailableAttribution.usersMediaSources) ||
    [];

  return (
    <Panel>
      <Row>
        <FormControl fullWidth sx={{ mr: 1 }}>
          <InputLabel id='campaign-select-label'>Campaign</InputLabel>
          <Select
            labelId='campaign-select-label'
            id='campaign-select'
            value={campaign}
            label='Campaign'
            onChange={(e) => setCampaign(e.target.value)}
          >
            {isAdmin ? <MenuItem value={'ANY'}>All</MenuItem> : null}
            {campaigns.map((c) => (
              <MenuItem value={c} key={c}>
                {c}
              </MenuItem>
            ))}
            {usersCampaigns.map((c) => (
              <MenuItem value={c} key={c} sx={{ color: 'gray' }}>
                {c}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id='media-source-select-label'>Media Source</InputLabel>
          <Select
            labelId='media-source-select-label'
            id='media-source-select'
            value={mediaSource}
            label='=Media Source'
            onChange={(e) => setMediaSource(e.target.value)}
          >
            {isAdmin ? <MenuItem value={'ANY'}>All</MenuItem> : null}
            {mediaSources.map((ms) => (
              <MenuItem value={ms} key={ms}>
                {ms}
              </MenuItem>
            ))}
            {usersMediaSources.map((ms) => (
              <MenuItem value={ms} key={ms} sx={{ color: 'gray' }}>
                {ms}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Row>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Дата</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setDateRange(item.selection)}
            moveRangeOnFirstSelection={false}
            ranges={[dateRange]}
            locale={rdrLocales.ru}
          />
        </AccordionDetails>
      </Accordion>

      <Button
        variant='contained'
        onClick={handleRequest}
        disabled={buttonDisabled}
        sx={{ marginTop: 2 }}
      >
        Поиск
      </Button>
    </Panel>
  );
};

ControlPanel.propTypes = {
  campaign: PropTypes.string,
  mediaSource: PropTypes.string,
  dateRange: PropTypes.object,
  setCampaign: PropTypes.func,
  setMediaSource: PropTypes.func,
  setDateRange: PropTypes.func,
  handleRequest: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default ControlPanel;
