import client from './apollo/init';
import { SIGN_IN } from './apollo/mutations';
import history from './history';

export const loginWithPassword = (username, password) => {
  return client.mutate({ mutation: SIGN_IN, variables: { username, password } })
    .then(({ data: { crmSignIn } }) => {
      if (crmSignIn) {
        localStorage.setItem('token', crmSignIn.token);
        return client.resetStore();
      }
    })
    .then(() => {
      history.push('/crm');
    });
};

export const logout = () => {
  localStorage.removeItem('token');
  history.push('/sign-in');
  return client.resetStore();
};
