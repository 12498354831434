import React, { PureComponent } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { ApolloProvider, Query } from 'react-apollo';
import { ThemeProvider } from 'styled-components';

import theme from 'core/ui/theme';
import history from 'core/api/history';
import client from 'core/api/apollo/init';
import { ResponsiveProvider } from 'core/api/responsiveContext';
import { getCurrentBreakpoint } from 'core/ui/helpers';
import { AccountProvider } from 'core/api/accounts/accountContext';
import GenericRoute from 'core/ui/components/GenericRoute';
import GlobalStyles from '../globalStyles';
import { CURRENT_CRM_USER } from 'core/api/apollo/queries';

// Import routes
import coreRoutes from 'core/api/routes';
import { StoreProvider } from 'core/api/storeContext';
import NotFound from 'core/ui/pages/NotFound';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import { InAppNotificationProvider } from 'core/api/toast/InAppNotificationsContext';
import Toast from 'core/ui/components/Toast';

const combinedRoutes = [...coreRoutes];

class AppRoot extends PureComponent {
  state = {
    currentBreakpoint: getCurrentBreakpoint(),
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ currentBreakpoint: getCurrentBreakpoint() });
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <InAppNotificationProvider>
          <Router history={history}>
            <ThemeProvider theme={theme}>
              <Query query={CURRENT_CRM_USER}>
                {({ error, data, loading: userLoading }) => {
                  if (error) {
                    console.log(error);
                    return <ErrorOccurred error={error} />;
                  }
                  return (
                    <StoreProvider>
                      <AccountProvider
                        value={{
                          currentUser: (data || {}).currentCrmUser,
                          userLoading,
                        }}
                      >
                        <ResponsiveProvider
                          value={{
                            breakpoint: this.state.currentBreakpoint,
                            isMobile: ['sm', 'xs'].includes(
                              this.state.currentBreakpoint
                            ),
                          }}
                        >
                          <Switch>
                            <Route exact path='/'>
                              <Redirect to='/sign-in' />
                            </Route>
                            {combinedRoutes.map((routeData) => (
                              <GenericRoute
                                key={routeData.path}
                                {...routeData}
                              />
                            ))}
                            <Route component={NotFound} />
                          </Switch>
                          <Toast />
                          <GlobalStyles />
                        </ResponsiveProvider>
                      </AccountProvider>
                    </StoreProvider>
                  );
                }}
              </Query>
            </ThemeProvider>
          </Router>
        </InAppNotificationProvider>
      </ApolloProvider>
    );
  }
}

export default AppRoot;
