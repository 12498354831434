import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Pagination,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';

const getPlans = (savedPlans = []) => {
  const uniqueArray = [...new Set(savedPlans)];
  return uniqueArray.join(', ');
};

const CRMAnalyticsUsers = ({
  data,
  count,
  page,
  setPage,
  setOnlyPurchased,
  onlyPurchased,
  loading,
  generateCsv
}) => {
  if (!data || !data.docs) return null;

  const { total, docs } = data;

  const changePage = (event, value) => {
    setPage(value - 1);
  };

  return (
    <Container>
      <Typography variant='h4' gutterBottom>
        Users
      </Typography>
      <PaginationContainer>
        <FormControlLabel
          control={
            <Switch
              checked={onlyPurchased}
              onChange={setOnlyPurchased}
              disabled={loading}
            />
          }
          label='Только оплатившие'
        />
        {total > count && (
          <Pagination
            count={Math.ceil(total / count)}
            page={page + 1}
            onChange={changePage}
            color='primary'
            showFirstButton
            showLastButton
          />
        )}
      </PaginationContainer>
      {loading ? (
        <LoadingCont>
          <CircularProgress />
        </LoadingCont>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Appflyer ID</StyledTableCell>
                <StyledTableCell>Campaign</StyledTableCell>
                <StyledTableCell>Media Source</StyledTableCell>
                <StyledTableCell>Purchased Plans</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs.map((user) => (
                <TableRow key={user._id}>
                  <StyledTableCell>{user._id}</StyledTableCell>
                  <StyledTableCell>
                    {user.serviceData.appsFlyerID}
                  </StyledTableCell>
                  <StyledTableCell>{user.serviceData.campaign}</StyledTableCell>
                  <StyledTableCell>
                    {user.serviceData.mediaSource}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getPlans(
                      (user.serviceData.payments &&
                        user.serviceData.payments.savedPlans) ||
                        []
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DownloadWrap>
        <Button onClick={generateCsv} variant='outlined'>
          Download CSV
        </Button>
      </DownloadWrap>
    </Container>
  );
};

CRMAnalyticsUsers.propTypes = {
  data: PropTypes.object,
  count: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  onlyPurchased: PropTypes.bool,
  setOnlyPurchased: PropTypes.func,
  loading: PropTypes.bool,
  generateCsv: PropTypes.func,
};

const Container = styled(Box)`
  margin: 20px;
`;

const PaginationContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  background-color: #f0f0f0;
`;

const LoadingCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const DownloadWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

export default CRMAnalyticsUsers;

// import React from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// import Pagination from 'core/ui/components/Pagination';
// import { FormControlLabel, Switch } from '@mui/material';

// const getPlans = (savedPlans = []) => {
//   const uniqueArray = [...new Set(savedPlans)];
//   return uniqueArray.join(', ');
// };

// const CRMAnalyticsUsers = ({ data, count, page, setPage, setOnlyPurchased, onlyPurchased, loading }) => {
//   if (!data || !data.docs) return null;

//   const { total, docs } = data;

//   const changePage = (page) => {
//     setPage(page - 1);
//   };

//   return (
//     <Container>
//       <h2>Users</h2>
//       <PaginationContainer>
//         <FormControlLabel
//           control={
//             <Switch checked={onlyPurchased} onChange={setOnlyPurchased} disabled={loading} />
//           }
//           label='Только оплатившие'
//         />
//         {total > count ? (
//           <Pagination
//             total={total}
//             update={changePage}
//             count={count}
//             current={page + 1}
//           />
//         ) : null}
//       </PaginationContainer>
//       <Table>
//         <thead>
//           <tr>
//             <Th>ID</Th>
//             <Th>Appflyer ID</Th>
//             <Th>Campaign</Th>
//             <Th>Media Source</Th>
//             <Th>Purchased Plans</Th>
//           </tr>
//         </thead>
//         <tbody>
//           {docs.map((user) => (
//             <tr key={user._id}>
//               <Td>{user._id}</Td>
//               <Td>{user.serviceData.appsFlyerID}</Td>
//               <Td>{user.serviceData.campaign}</Td>
//               <Td>{user.serviceData.mediaSource}</Td>
//               <Td>
//                 {getPlans(
//                   (user.serviceData.payments &&
//                     user.serviceData.payments.savedPlans) ||
//                     []
//                 )}
//               </Td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </Container>
//   );
// };

// CRMAnalyticsUsers.propTypes = {
//   data: PropTypes.object,
//   count: PropTypes.number,
//   page: PropTypes.number,
//   setPage: PropTypes.func,
//   onlyPurchased: PropTypes.bool,
//   setOnlyPurchased: PropTypes.func,
//   loading: PropTypes.bool
// };

// const Container = styled.div`
//   margin: 20px;
// `;

// const Table = styled.table`
//   width: 100%;
//   border-collapse: collapse;
// `;

// const Th = styled.th`
//   padding: 10px;
//   background-color: #f0f0f0;
//   border: 1px solid #ddd;
// `;

// const Td = styled.td`
//   padding: 10px;
//   border: 1px solid #ddd;
// `;

// const PaginationContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   margin-bottom: 8px;
// `;

// export default CRMAnalyticsUsers;
