import {createGlobalStyle} from 'styled-components';
import styledNormalize from 'styled-normalize';

import TT_NORMS_REGULAR from '../assets/fonts/TT-Norms-Pro-Regular.ttf';
import TT_NORMS_LIGHT from '../assets/fonts/TT-Norms-Pro-Light.ttf';
import TT_NORMS_MEDIUM from '../assets/fonts/TT-Norms-Pro-Medium.ttf';
import TT_NORMS_BOLD from '../assets/fonts/TT-Norms-Pro-Bold.ttf';
import TT_NORMS_THIN from '../assets/fonts/TT-Norms-Pro-Thin.ttf';
import TT_NORMS_DEMIBOLD from '../assets/fonts/TT-Norms-Pro-DemiBold.ttf';
import TT_NORMS_BLACK from '../assets/fonts/TT-Norms-Pro-Black.ttf';
import TT_NORMS_EXTRALIGHT from '../assets/fonts/TT-Norms-Pro-ExtraLight.ttf';
import TT_NORMS_NORMAL from '../assets/fonts/TT-Norms-Pro-Normal.ttf';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  * {
    box-sizing: border-box;
    outline: none !important;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  body {
    font-family: ${p => p.theme.font.family.text};
    font-weight: normal;
    line-height: ${p => p.theme.font.lineHeight.base};
    letter-spacing: ${p => p.theme.font.letterSpacing.base};
    background-color: #F9FAFC;
    &:after {
      position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${p => p.theme.colors.black};
    font-family: ${p => p.theme.font.family.header};
  }
  h1 {
    font-size: ${p => p.theme.font.size.h1};
  }
  h2 {
    font-size: ${p => p.theme.font.size.h2};
  }
  h3 {
    font-size: ${p => p.theme.font.size.h3};
  }
  h4 {
    font-size: ${p => p.theme.font.size.h4};
  }
  h5 {
    font-size: ${p => p.theme.font.size.h5};
  }
  h6 {
    font-size: ${p => p.theme.font.size.h6};
  }
  p {
    color: ${p => p.theme.colors.black};
    font-size: ${p => p.theme.font.size.base};
  }
  .reorder-wrapper-item {
    position: relative;
    transition: top 1s ease-in;
  }
  @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_THIN});
      font-weight: 100;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_EXTRALIGHT});
      font-weight: 200;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_LIGHT});
      font-weight: 300;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_REGULAR});
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_NORMAL});
      font-weight: 500;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_MEDIUM});
      font-weight: 600;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_DEMIBOLD});
      font-weight: 700;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_BOLD});
      font-weight: 800;
      font-style: normal;
    }
    @font-face {
      font-family: 'TT Norms';
      src: url(${TT_NORMS_BLACK});
      font-weight: 900;
      font-style: normal;
    }
`;

export default GlobalStyle;
