// Layouts
import PublicLayout from 'core/ui/layouts/PublicLayout';
import AuthenticatedLayout from 'core/ui/layouts/AuthenticatedLayout';

// Pages
import LoginPage from 'core/ui/pages/LoginPage';
import CrmPage from 'crm/ui/pages/crm';

export default [
  {
    component: LoginPage,
    layout: PublicLayout,
    isPublic: true,
    redirectLogged: true,
    path: '/sign-in',
  },
  {
    component: CrmPage,
    layout: AuthenticatedLayout,
    path: '/crm',
    exact: true,
  },
];
