import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { Flex, SvgIcon, Input, Avatar } from 'core/ui/atoms';
import AVATAR from 'core/assets/images/avatar.png';

import { withAccount } from 'core/api/accounts/accountContext';
import withStore from 'core/api/storeContext';

@withStore
@withAccount
class PageHeader extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.shape({
      personalData: PropTypes.shape({
        firstName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
    searchText: PropTypes.string,
    setStore: PropTypes.func,
  };

  state = {
    localSearchText: this.props.searchText || '',
  };

  saveSearch = debounce((textValue) => {
    this.props.setStore({ searchText: textValue });
  }, 500);

  handleInputChange = (event) => {
    this.setState({
      localSearchText: event.target.value,
    });
    this.saveSearch(event.target.value);
  };

  render() {
    const { currentUser: { personalData: { firstName } } } = this.props;
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Search>
          <SvgIcon.Search/>
          <SearchInput
            placeholder="Поиск"
            value={this.state.localSearchText}
            onChange={this.handleInputChange}
          />
        </Search>
        <ProfileWrapper>
          <BellWrapper>
            <SvgIcon.Bell/>
            <span/>
          </BellWrapper>
          <Avatar src={AVATAR} alt={firstName}/>
          <UserName>{firstName}</UserName>
          <ArrowDown/>
        </ProfileWrapper>
      </Flex>
    );
  }
}

const Search = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const SearchInput = styled(Input)`
  background: none;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  font-size: ${p => p.theme.font.size.small};
  ::placeholder {
    color: #d9d9d9;
  }
`;
const UserName = styled.span`
  color: #535353;
  font-size: ${p => p.theme.font.size.base};
  font-weight: 500;
  margin-right: 50px;
`;

const ArrowDown = styled.span`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.25px 4.25px 0 4.25px;
  border-color: #c4c4c4 transparent transparent transparent;
`;

const BellWrapper = styled.div`
  position: relative;
  margin-right: 20px;
  span {
    position: absolute;
    right: -1px;
    top: -1px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${p => p.theme.colors.primary};
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;  
`;

export default PageHeader;
