import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  Container,
  TextField,
  Card,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import { loginWithPassword } from 'core/api/actions';

class LoginPage extends PureComponent {
  state = {
    username: '',
    password: '',
    error: '',
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = () => {
    const { username, password } = this.state;
    if (!username || !password) {
      return;
    }
    loginWithPassword(username, password).catch((e) => {
      const [error] = e.graphQLErrors;
      this.setState({ error: (error && error.message) || e.message });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.onSubmit();
  };

  render() {
    const { username, password, error } = this.state;
    return (
      <Page>
        <StyledContainer maxWidth='xs'>
          <StyledCard>
            <Typography variant='h4' component='h1' gutterBottom>
              Welcome,
            </Typography>
            <Typography variant='h6' component='h2' gutterBottom>
              <PrimaryText>Log in</PrimaryText> to continue
            </Typography>
            <form onSubmit={this.handleSubmit}>
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                label='Username'
                name='username'
                value={username}
                onChange={this.onChange}
                autoFocus
              />
              <TextField
                variant='outlined'
                margin='normal'
                fullWidth
                label='Password'
                type='password'
                name='password'
                value={password}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyledButton
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
              >
                Log in
              </StyledButton>
              {error && (
                <Alert severity='error' style={{ marginTop: '10px' }}>
                  {error}
                </Alert>
              )}
            </form>
          </StyledCard>
        </StyledContainer>
      </Page>
    );
  }
}

export default LoginPage;

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCard = styled(Card)`
  padding: 20px;
  width: 100%;
  text-align: center;
`;

const PrimaryText = styled.span`
  color: #3f51b5;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

// import React, { PureComponent } from 'react';
// import styled from 'styled-components';

// import {
//   Page,
//   Container,
//   Input,
//   Card,
//   Heading as HeadingAtom,
//   FormGroup,
//   Primary,
//   Button,
//   InputErrorMessage,
// } from 'core/ui/atoms/index';
// import { loginWithPassword } from 'core/api/actions';
// import ErrorBoundary from '../components/ErrorBoundary';

// class LoginPage extends PureComponent {
//   state = {
//     username: '',
//     password: '',
//   };

//   onChange = e => this.setState({ [e.target.name]: e.target.value });

//   onSubmit = () => {
//     const { username, password } = this.state;
//     if (!username || !password) {
//       return;
//     }
//     loginWithPassword(username, password).catch(e => {
//       const [error] = e.graphQLErrors;
//       this.setState({ error: error && error.message || e.message });
//     });
//   };

//   handleSubmit = (e) => {
//     e.preventDefault();
//   };

//   render() {
//     const { username, password, error } = this.state;
//     return (
//       <Page>
//         <Container>
//           <CardCenter padded>
//             <Heading>Welcome, <br/> <Primary>Log in</Primary> to continue</Heading>
//             <form onSubmit={this.handleSubmit}>
//               <FormGroup>
//                 <Input type="text" placeholder="Username" value={username} name="username" onChange={this.onChange}/>
//               </FormGroup>
//               <FormGroup>
//                 <Input type="password" placeholder="Password" value={password} name="password" onChange={this.onChange}/>
//               </FormGroup>
//               <Button onClick={this.onSubmit}>Log in</Button>
//               <FormGroup>
//                 {error && <InputErrorMessage>{error}</InputErrorMessage>}
//               </FormGroup>
//             </form>
//           </CardCenter>
//         </Container>
//       </Page>
//     );
//   }
// }

// export default LoginPage;

// const Heading = styled(p => <HeadingAtom level={3} {...p} />)`
//   margin-top: 0;
//   margin-bottom: 10px;
// `;

// const CardCenter = styled(Card)`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   height: 300px;
//   width: 300px;
// `;
