import gql from 'graphql-tag';

import { crmUserData } from './fragments';

export const GET_AVAILABLE_ATTRIBUTION = gql`
  query getAvailableAttribution {
    getAvailableAttribution {
      campaigns
      mediaSources
      usersCampaigns
      usersMediaSources
    }
  }
`;

export const GET_CRM_ANALYTICS_DATA = gql`
  query getCRMAnalyticsData($input: CrmAnalyticsInput!) {
    getCRMAnalyticsData(input: $input) {
      registeredUsers
      blackPurchases
      premiumPurchases
      webBlackPurchases
      webPremiumPurchases
    }
  }
`;

export const GET_USERS_FOR_CRM_ANALYTICS = gql`
  query getUsersForCRMAnalytics($input: CrmAnalyticsInput, $page: Int, $count: Int) {
    getUsersForCRMAnalytics(input: $input, page: $page, count: $count) {
      total
      limit
      docs {
        ...CrmUserData
      }
    }
  }
  ${crmUserData}
`;