import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Container } from 'core/ui/atoms';
import PageHeader from '../components/PageHeader';
import Sidebar from '../components/Sidebar';

class AuthenticatedLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <Layout>
        {/* <Sidebar/> */}
        <Content>
          <ContainerStyled>
            {/* <PageHeader/> */}
            {this.props.children}
          </ContainerStyled>
        </Content>
      </Layout>
    );
  }
}

export default AuthenticatedLayout;

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Content = styled.div`
  width: 100%;
  /* margin-left: 220px; */
`;

const ContainerStyled = styled(Container)`
  ${(p) => p.theme.max('sm')`
        padding: 0;
      `}
`;
