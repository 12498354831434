import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Grid, useMediaQuery, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CRMAnalyticsData = ({ data, loading }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) {
    return (
      <LoadingCont>
        <CircularProgress />
      </LoadingCont>
    );
  }

  if (!data) return null;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Results
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Circle>
              <CircleText>{data.registeredUsers}</CircleText>
            </Circle>
            <Typography variant="body1">Number of registered users</Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Circle>
              <CircleText>{data.blackPurchases}</CircleText>
            </Circle>
            <Typography variant="body1">Number of black purchases made</Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Circle>
              <CircleText>{data.premiumPurchases}</CircleText>
            </Circle>
            <Typography variant="body1">Number of premium purchases made</Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Circle>
              <CircleText>{data.webBlackPurchases}</CircleText>
            </Circle>
            <Typography variant="body1">Number of web black purchases made</Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper>
            <Circle>
              <CircleText>{data.webPremiumPurchases}</CircleText>
            </Circle>
            <Typography variant="body1">Number of web premium purchases made</Typography>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

CRMAnalyticsData.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
};

const Container = styled(Box)`
  margin: 20px;
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Circle = styled(Box)`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const CircleText = styled(Typography)`
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
`;

const LoadingCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export default CRMAnalyticsData;


// import React from 'react';
// import styled from 'styled-components';
// import PropTypes from 'prop-types';

// const CRMAnalyticsData = ({ data }) => {
//   if (!data) return null;

//   return (
//     <Container>
//       <h2>Results</h2>
//       <div>
//         <p>Number of registered users: {data.registeredUsers}</p>
//         <p>Number of black purchases made: {data.blackPurchases}</p>
//         <p>Number of premium purchases made: {data.premiumPurchases}</p>
//         <p>Number of web black purchases made: {data.webBlackPurchases}</p>
//         <p>Number of web premium purchases made: {data.webPremiumPurchases}</p>
//       </div>
//     </Container>
//   );
// };

// CRMAnalyticsData.propTypes = {
//   data: PropTypes.object,
// };

// const Container = styled.div`
//   margin: 20px;
// `;

// export default CRMAnalyticsData;
