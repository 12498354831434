import gql from 'graphql-tag';

export const CURRENT_CRM_USER = gql`
  query currentCrmUser {
    currentCrmUser {
      _id
      username
      accessKey
      roles
      campaigns
      mediaSources
    }
  }
`;
